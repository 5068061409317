import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import gsap from "gsap";


export const OurStory = () => {

  const whoWeAre = React.useRef(null);

  React.useEffect(() => {
    gsap.fromTo(".our-story-head", { x: -1000 }, {
      x: 0, duration: 1, scrollTrigger: { trigger: ".our-story-head", start: "center 80%" }
    });
    gsap.fromTo(".whowe-anim", {
      y: 50, opacity: 0
    }, {
      y: 0, opacity: 1, duration: 0.3, stagger: { amount: 0.8 }, scrollTrigger: {
        trigger: ".whowe-anim",
        start: "bottom 85%"
      }
    });
  })

  return (
    <div className="section-content our-story-bg pb-100">
      <Container className="pb-50">
        <Row className="mx-0 justify-content-center pb-100 mb-100">
          <Col lg={8}>
            <div className="heading-container our-story-head text-md-start mb-50">
              <h2>Who We Are</h2>
              <p>We are one of YOU!</p>
            </div>
            <p className="mb-4 text-center text-md-start whowe-anim">
              From the roots, we were <b>Patients</b>. We are coming to you as sprouting <b>Education</b> and growing for you as <b>Medication</b>.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
