import React, { useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../common/images/logo.png";
import { gsap } from "gsap";

export const Header = () => {

  const [headerHight, setHeaderHeight] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const headerRef = useRef(null);
  React.useEffect(() => {
    gsap.fromTo(headerRef.current, { y: -80 }, { y: 0, duration: 0.2 })
  }, [])

  const handleheader = () => {
    console.log(window.innerWidth < 992)
    if (window.innerWidth < 992) {
      setHeaderHeight(!headerHight);
      setOpen(!open);
    }
  }

  return (
    <header className={headerHight ? "fixed-ncsd" : null} ref={headerRef}>
      <Navbar expand="lg">
        <Container className="header-container">
          <Navbar.Brand>
            <Link to={"/"}>
              <img src={logo} width="230" className="header-logo" loading="lazy" alt="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            className="toggle-button" onClick={handleheader}
          />
          <Navbar.Collapse className="justify-content-end" id="navbarScroll" in={open}>
            <Nav className="my-2 my-lg-0">
              <NavLink className="nav-link" to={"/about"} onClick={handleheader}>
                About
              </NavLink>
              <NavLink className="nav-link" to={"/products"} onClick={handleheader}>
                Products
              </NavLink>
              <NavLink className="nav-link" to={"/medical"} onClick={handleheader}>
                Medical
              </NavLink>
              <NavLink className="nav-link" to={"/contact"} onClick={handleheader}>
                Contact
              </NavLink>
              <NavLink className="nav-link" to={"/product-det"} onClick={handleheader}>
                Shop
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header >
  );
};
