import { configureStore } from "@reduxjs/toolkit";
import ContactSlice from "../Slices/ContactSlices";
import ShopSlices from "../Slices/ShopSlices";
import RegistrationApplicationSlices from "../Slices/RegistrationApplicationSlices";

export const store = configureStore({
  reducer: {
    contact: ContactSlice.reducer,
    shop: ShopSlices.reducer,
    register_form_data: RegistrationApplicationSlices.reducer,
  },
});
