import React from "react";
import { ProductSectionOne } from "../features/Products/ProductHero";


export const ProductPage = () => {
    return (
        <main className="overflow-hidden">
            <ProductSectionOne />
        </main>
    );
};