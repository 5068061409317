import { createSlice } from "@reduxjs/toolkit";

const ShopSlices = createSlice({
    name: 'Shop',
    initialState: {
        shop: false
    },
    reducers: {
        setShopShow(state, action) {
            state.shop = action.payload;
        }
    }
});


export const ShopAction = ShopSlices.actions;

export default ShopSlices;