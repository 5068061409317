import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import gsap from "gsap";

export const AppoinmentForm = () => {

  const appoinHead = React.useRef(null);

  React.useEffect(() => {
    gsap.fromTo(appoinHead.current, {
      x: -900, opacity: 0
    }, {
      x: 0, opacity: 1, duration: 0.5
    })
    gsap.fromTo(".appoint-text", {
      y: 50, opacity: 0
    }, {
      y: 0, opacity: 1, duration: 0.3, stagger: { amount: 0.5 }, delay: 0.5
    })

    gsap.fromTo(".medical-background", {
      y: -500, opacity: 0
    }, {
      y: 0, opacity: 1, duration: 1, delay: 0.7
    })
  })
  return (
    <Container fluid className="main-content overflow-hidden px-0 vh-100">
      <Row className="vh-100 medical-row mx-0 medical-background-responsive">
        <Col
          xl={6}
          className="pe-xl-0 responsive-bg ps-xl-150 position-relative align-self-center"
        >
          <div className="heading-container welcome-head mb-100" ref={appoinHead}>
            <h1 className="welcome-title">CannJah Care</h1>
            <p className="fw-bold h6">"Our Specialized Medical Program"</p>
          </div>

          <h4 className="mb-4 pe-xl-4 appoint-text">Now Accepting Medical Patients</h4>
          <p className="pe-xl-5 mb-5 mb-2 appoint-text">
            <b>Register</b> yourself to become a <b>Member</b>, submit your <b>Prescription</b>,and start <b>Saving</b> by purchasing directly from Us.
          </p>
          <Link to='/medical-form' className="btn btn-success appoint-text rounded-0 px-4 py-2 mt-3">Register <FaLongArrowAltRight /></Link>
          {/* <h4 className="mb-2 pe-xl-4">Why Medical Cannabis</h4>
          <p>Cannabis Insurance Coverage</p> */}
        </Col>
        <Col xl={6} className="medical-background">
          <Form className='d-none'>
            <h3 className='fw-bold mb-4'>Registration Form </h3>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" className='' placeholder="eg. Jacob Jones" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" className='' placeholder="eg. infoeasy@gmail.com" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Location</Form.Label>
              <Form.Control type="text" className='' placeholder="eg. SF, Bay Area, Germany" />
            </Form.Group>

            <Form.Group className="mb-4" controlId="formBasicPassword">
              <Form.Label>Add prescription Photo</Form.Label>
              <Form.Control type="file" className='' />
            </Form.Group>
            <Button variant="dark" className='fw-bold w-100' type="submit">
              Submit <FaLongArrowAltRight />
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
