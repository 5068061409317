import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import gsap from "gsap";

export const WhyMedical = () => {

  const headWhyMed = React.useRef(null);
  React.useEffect(() => {
    gsap.fromTo(".medwhy-patient", {
      y: 50, opacity: 0
    }, {
      y: 0, opacity: 1, duration: 0.3, stagger: { amount: 0.8 }, scrollTrigger: {
        trigger: headWhyMed.current,
        start: "bottom 70%",
      }
    });
  })
  return (
    <div className="section-content Accepting-Patients main-bg">
      <Container>
        <Row className="mx-0">
          <Col sm={12} className="text-center" ref={headWhyMed}>
            <h2 className="medwhy-patient">Need a prescription?</h2>
            <h4 className="medwhy-patient">CannXperts can assist.</h4>
            <a className="btn btn-light medwhy-patient rounded-0 px-4 py-2 mt-4" href="/contact">CannXpert</a>

          </Col>
        </Row>
        <Row className="mx-0 mt-4 mt-sm-5">
          <Col lg={3} className="">
            <ul>
              <li className="medwhy-patient">Chronic Pain</li>
              <li className="medwhy-patient">Appetite Loss</li>
            </ul>
          </Col>
          <Col lg={3} className="">
            <ul>
              <li className="medwhy-patient">Stress</li>
              <li className="medwhy-patient">Anxiety</li>
              <li className="medwhy-patient">Fibromyalgia</li>

            </ul>
          </Col>
          <Col lg={3} className="">
            <ul>
              <li className="medwhy-patient">Arthritis</li>
              <li className="medwhy-patient">Muscular Dystrophy</li>
              <li className="medwhy-patient">PTSD</li>
            </ul>
          </Col>

          <Col lg={3} className="">
            <ul>
              <li className="medwhy-patient">Depression</li>
              <li className="medwhy-patient">Epilepsy </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
