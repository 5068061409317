import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Box from '@mui/material/Box';
import { FaLongArrowAltRight } from "react-icons/fa";
import dayjs from 'dayjs';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RadioGroup from '@mui/material/RadioGroup';
import { useFormik } from 'formik';
import { ResponsFormSchema } from '../../../services/Schemas/MedicalFormValidation';
import { useDispatch, useSelector } from 'react-redux';
import { RegistrationApplicationAction } from '../../../services/Slices/RegistrationApplicationSlices';

export const ResponsibleForm = ({ handleNext, handleBack, applicant_name }) => {


    const dispatch = useDispatch();

    const { name, resposible_name, responsible_date_of_birth, responsible_gender, accepted } = useSelector((state) => state.register_form_data)

    const [respoName, setRespoName] = React.useState(resposible_name.split(' ')[0]);
    const [respoSurname, setRespoSurname] = React.useState(resposible_name.split(' ')[1]);

    const isInCurrentYear = (date) => date.get('year') >= dayjs().get('year');


    let initialValue = {
        r_given_name: resposible_name.split(' ')[0],
        r_surname: resposible_name.split(' ')[1],
        r_dob: responsible_date_of_birth,
        r_gender: responsible_gender,
        is_responsible: accepted
    }

    const responsibleForm = useFormik({
        initialValues: initialValue,
        // validationSchema: ResponsFormSchema,
        onSubmit: (values, action) => {

            dispatch(RegistrationApplicationAction.setResponsibleData(values))
            handleNext()
        }
    })

    const [cusGen, setCusGen] = React.useState(responsible_gender);

    const { values, touched, errors, handleBlur, handleChange, handleSubmit } = responsibleForm;

    const handsleName = (e) => {
        setRespoName(e.target.value);
        responsibleForm.setFieldValue('r_given_name', e.target.value);
    }
    const handlesurname = (e) => {
        setRespoSurname(e.target.value)
        responsibleForm.setFieldValue('r_surname', e.target.value);
    }


    return (
        <form className='' onSubmit={handleSubmit}>
            <h3 className='fw-bold mb-2'>Individual(s) Responsible For The Applicant </h3>
            <p className='mb-4'>(complete this section only if applicable)</p>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <label>Given Name</label>
                    <input type="text" name='r_given_name' value={values.r_given_name} className='cus-form-control' placeholder="eg. Jacob" onChange={handsleName} onBlur={handleBlur} />
                    {errors.r_given_name && touched.r_given_name ?
                        <p className="form-error">{errors.r_given_name}</p> : null}
                </Grid>
                <Grid item xs={12} md={6}>
                    <label>Surname</label>
                    <input type="text" name='r_surname' value={values.r_surname} onBlur={handleBlur} className='cus-form-control' placeholder="eg. Jones" onChange={handlesurname} />
                    {errors.r_surname && touched.r_surname ?
                        <p className="form-error">{errors.r_surname}</p> : null}
                </Grid>
                <Grid item xs={12} md={9}>
                    <label>Date of Birth</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker onChange={(date) => responsibleForm.setFieldValue('r_dob', dayjs(date).format('YYYY-MM-DD'))} onBlur={handleBlur} shouldDisableYear={isInCurrentYear} name='r_dob' value={dayjs(values.r_dob)} />
                    </LocalizationProvider>
                    {errors.r_dob && touched.r_dob ?
                        <p className="form-error">{errors.r_dob}</p> : null}
                </Grid>
                <Grid item md={8}>
                    <label>Gender</label>
                    <RadioGroup
                        name="r_gender"
                    >
                        <Grid container spacing={3}>
                            <Grid item md={4}>
                                <FormControlLabel checked={cusGen === 'female' ? true : false} onChange={() => { setCusGen('female'); responsibleForm.setFieldValue('r_gender', 'female') }} value="female" control={<Radio />} label="Female" />
                            </Grid>
                            <Grid item md={4}>
                                <FormControlLabel checked={cusGen === 'male' ? true : false} onChange={() => { setCusGen('male'); responsibleForm.setFieldValue('r_gender', 'male') }} value="male" control={<Radio />} label="Male" />
                            </Grid>
                            <Grid item md={4}>
                                <FormControlLabel checked={cusGen === 'other' ? true : false} onChange={() => { setCusGen('other'); responsibleForm.setFieldValue('r_gender', 'other') }} value="other" control={<Radio />} label="Other" />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                    {errors.r_gender && touched.r_gender ?
                        <p className="form-error">{errors.r_gender}</p> : null}
                </Grid>
                <Grid item xs={12} md={9}>
                    <FormControlLabel control={<Checkbox checked={values.is_responsible} value={`I, ${respoName !== '' ? respoName : '---------'} ${respoSurname !== '' ? respoSurname : '---------'} responsible for ${applicant_name}`} onChange={handleChange} onBlur={handleBlur} name='is_responsible' />} label={`I, ${respoName !== '' ? respoName : '---------'} ${respoSurname !== '' ? respoSurname : '---------'} responsible for ${name}`} />

                    {errors.is_responsible && touched.is_responsible ?
                        <p className="form-error">{errors.is_responsible}</p> : null}
                </Grid>
                <Grid item md={12}>
                    <Button className='fw-bold me-3' type='submit'>
                        Continue <FaLongArrowAltRight />
                    </Button>
                    <Button variant='dark' className='fw-bold' onClick={() => handleBack()}>
                        Back
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}
