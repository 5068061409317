import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    name: '',
    email: '',
    date_of_birth: '',
    gender: '',

    residence_address1: '',
    residence_address2: '',
    residence_city: '',
    residence_province: '',
    residence_postal_code: '',
    mobile_number: '',
    fax: '',

    mailing_address1: '',
    mailing_address2: '',
    mailing_city: '',
    mailing_province: '',
    mailing_postal_code: '',

    shipping_address1: '',
    shipping_address2: '',
    shipping_city: '',
    shipping_province: '',
    shipping_postal_code: '',

    resposible_name: '',
    responsible_date_of_birth: '',
    responsible_gender: '',

    practitioner_name: '',
    clinic_name: '',
    practitioner_address1: '',
    practitioner_address2: '',
    practitioner_city: '',
    practitioner_province: '',
    practitioner_postal_code: '',
    practitioner_mobile_number: '',
    practitioner_fax: '',
    practitioner_email: '',

    accepted: false,

    // extra params
    practionerOn: false,
    shipcheck: ''
}

const RegistrationApplicationSlices = createSlice({
    name: 'RegisterFormData',
    initialState: initialState,
    reducers: {
        setApplicantData(state, action) {
            state.name = action.payload.given_name + " " + action.payload.surname;
            state.email = action.payload.email;
            state.date_of_birth = action.payload.dob;
            state.gender = action.payload.gender;
        },
        setResidenceData(state, action) {

            state.residence_address1 = action.payload.address1;
            state.residence_address2 = action.payload.address2;
            state.residence_city = action.payload.city;
            state.residence_province = action.payload.province;
            state.residence_postal_code = action.payload.postal_code;
            state.mobile_number = action.payload.mobile_number;
            state.fax = action.payload.fax;
        },
        setMailandShipData(state, action) {

            state.mailing_address1 = action.payload.m_address1;
            state.mailing_address2 = action.payload.m_address2;
            state.mailing_city = action.payload.m_city;
            state.mailing_province = action.payload.m_province;
            state.mailing_postal_code = action.payload.m_postal_code;
            state.shipcheck = action.payload.shipcheck;

            switch (action.payload.shipcheck) {
                case 'practitioner_address':
                    state.practionerOn = true;
                    state.shipping_address1 = '';
                    state.shipping_address2 = '';
                    state.shipping_city = '';
                    state.shipping_province = '';
                    state.shipping_postal_code = '';
                    break;
                case 'residence_address':
                    state.practionerOn = false;
                    state.shipping_address1 = state.residence_address1;
                    state.shipping_address2 = state.residence_address2;
                    state.shipping_city = state.residence_city;
                    state.shipping_province = state.residence_province;
                    state.shipping_postal_code = state.residence_postal_code;
                    break;
                case 'mailing_address':
                    state.practionerOn = false;
                    state.shipping_address1 = state.mailing_address1;
                    state.shipping_address2 = state.mailing_address2;
                    state.shipping_city = state.mailing_city;
                    state.shipping_province = state.mailing_province;
                    state.shipping_postal_code = state.mailing_postal_code;
                    break;
            }

            if (action.payload.shipcheck === "practitioner_address") {
                state.practionerOn = true;
            } else {
                state.practionerOn = false;
            }
        },
        setResponsibleData(state, action) {
            state.accepted = action.payload.is_responsible;
            state.resposible_name = action.payload.r_given_name + ' ' + action.payload.r_surname;
            state.responsible_date_of_birth = action.payload.r_dob;
            state.responsible_gender = action.payload.r_gender;
        },
        setPractitionerData(state, action) {
            state.practitioner_name = action.payload.pra_given_name + " " + action.payload.pra_surname;
            state.clinic_name = action.payload.pra_business_name;
            state.practitioner_address1 = action.payload.pra_address1;
            state.practitioner_address2 = action.payload.pra_address2;
            state.practitioner_city = action.payload.pra_city;
            state.practitioner_province = action.payload.pra_province;
            state.practitioner_postal_code = action.payload.pra_postal_code;
            state.practitioner_mobile_number = action.payload.pra_mobile_number;
            state.practitioner_fax = action.payload.pra_fax;
            state.practitioner_email = action.payload.pra_email;

            if (state.shipcheck === "practitioner_address") {
                state.shipping_address1 = state.practitioner_address1;
                state.shipping_address2 = state.practitioner_address2;
                state.shipping_city = state.practitioner_city;
                state.shipping_province = state.practitioner_province;
                state.shipping_postal_code = state.practitioner_postal_code;
            }
        },
        resetState: () => initialState
    }
});


export const RegistrationApplicationAction = RegistrationApplicationSlices.actions;

export default RegistrationApplicationSlices;