import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import gsap from 'gsap';

export const AboutPharm = () => {

    const heading = React.useRef(null);

    React.useEffect(() => {
        gsap.fromTo(heading.current, { x: -900 }, {
            x: 0, duration: 1, scrollTrigger: { trigger: heading.current, start: 300 }
        })
        gsap.fromTo(".text-animate", {
            y: 50, opacity: 0
        }, {
            y: 0, opacity: 1, duration: 0.3, stagger: { amount: 0.5 }, scrollTrigger: {
                trigger: heading.current,
                start: 500,
            }
        })
    }, [])
    return (
        <div className="section-content about-pharm pb-0">
            <Container className="">
                <Row className="mx-0 aboutpharm-pattern">
                    <Col md={9}>
                        <div className="heading-container our-story-head text-md-start mb-50" ref={heading}>
                            <h2>Who We Are</h2>
                            <p>We Are One Of You</p>
                        </div>
                        <h5 className='mb-4 fw-bold text-animate'>"We started this journey as Patients seeking access to clean Medical Marijuana, then decided to take matters into our own hands."</h5>
                        <h5 className="text-animate">From <strong>Neuromuscular</strong> Disease to a Canadian Cannabis <strong>Licensed Producer</strong>.</h5>
                        <h5 className="text-animate">Our Expertise is around Cultivation & Processing with a Farmgate store.</h5>
                        <h5 className="text-animate">Our facility is vertically integrated with a <span className='text-main'>Hybrid Model</span> for medical & recreational sales.</h5>

                        <Link to='/about' className="btn btn-success rounded-0 px-4 py-2 mt-5 text-animate">Learn More <FaLongArrowAltRight /></Link>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
