import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ShopAction } from "../services/Slices/ShopSlices";
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const UserLayout = ({ shopShow }) => {

  gsap.registerPlugin(ScrollTrigger)
  const shop = useSelector(state => state.shop.shop);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const AgeVerification = localStorage.getItem("Age");

  useEffect(() => {

    if (shop) {
      document.getElementById("script").innerHTML = "";
      dispatch(ShopAction.setShopShow(false));
    }

    if (AgeVerification == null) {
      navigate("/verification");
    } else if (AgeVerification <= 18) {
      navigate("/failed-verification");
    }

  })
  return (
    <div>
      <ScrollToTop />
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};
