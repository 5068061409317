import React from 'react'

export const Dashboard = () => {
    return (
        <>
            <div className='content-header'>Header</div>
            <div className='content-body'>Body</div>
        </>
    )
}
