import React from "react";
import { Container } from "react-bootstrap";
import { Link, Outlet, useLocation } from "react-router-dom";
import logo from "../common/images/logo.png";
import { Sidebar } from "../components/Sidebar";

export const AdminLayout = () => {
  const location = useLocation();
  const [url, setUrl] = React.useState(null);

  React.useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  return (<>
    <div className="dashbaord">
      <header className="header">
        <Container fluid className="h-100">
          <div className="d-flex h-100">
            <div className="flex-grow-1 align-items-center d-flex gap-3">
              <div className="ds-logo-con">
                <Link to="/">
                  <img src={logo} width="200" alt="logo" className="ds-logo" />
                </Link>
              </div>
            </div>
            <div className="flex-grow-0 align-items-center d-flex gap-3">
              <div className="profile">
                {/* <img src="https://picsum.photos/id/237/200/300" className="profile-pic" alt="profile pic" /> */}
              </div>
            </div>
          </div>
        </Container>
      </header>
      <main className="box-conta">
        <Sidebar />
        <div className="main-content">
          <Outlet />
        </div>
      </main>
    </div>
  </>
  );
};
