import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { lazy, Suspense } from "react";
import { UserLayout } from "./UserLayout";
import { AdminLayout } from "./AdminLayout";
import { Home } from "../pages/Home";
import { AboutUs } from "../pages/AboutUs";
import { ContactUs } from "../pages/ContactUs";
import { ErrorPage } from "../pages/ErrorPage";
import { Profile } from "../pages/Profile";
import { AgeVerification } from "../pages/AgeVerification";
import { SorryMessage } from "../pages/sorryMessage";
import { Medical } from "../pages/Medical";
import { ProductPage } from "../pages/Product";
import { Products } from "../pages/Products";
import { ProductDetails } from "../pages/ProductDetails";
import { Dashboard } from "../pages/Dashboard";
import { AdSupport } from "../pages/AdSupport";
import { MedicalForm } from "../features/Medical/MedicalForm";

// Product Details
// import { BananaHammock } from "../features/ProductDetails/BananaHammock";
// import { GorillaGlue } from "../features/ProductDetails/GorillaGlue";
// import { GrapeDiamond } from "../features/ProductDetails/GrapeDiamond";
import { ThirdProduct } from "../pages/ThirdProduct";

import { MainLoader } from "../components/MainLoader";

const BananaHammock = lazy(() => import('../features/ProductDetails/BananaHammock'));
const GorillaGlue = lazy(() => import('../features/ProductDetails/GorillaGlue'));
const GrapeDiamond = lazy(() => import('../features/ProductDetails/GrapeDiamond'));


const Router_pro = () => {
  return (

    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route element={<UserLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/medical" element={<Medical />} />
          <Route path="/medical-form" element={<MedicalForm />} />
          <Route path="/products" element={<Products />} />
          <Route path="/preproducts" element={<ProductPage />} />
          <Route path="/Banana-Hammock" element={<BananaHammock />} />
          <Route path="/Gorilla-Glue" element={<GorillaGlue />} />
          <Route path="/Grape-Diamond" element={<GrapeDiamond />} />
        </Route>
        <Route element={<AdminLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/support" element={<AdSupport />} />
          <Route path="/dashboard/profile" element={<Profile />} />
        </Route>
        <Route path="/verification" element={<AgeVerification />} />
        <Route path="/failed-verification" element={<SorryMessage />} />
        <Route path="/product-det" element={<ThirdProduct />} />
      </Routes>
    </Suspense>
  )
}

export default Router_pro;