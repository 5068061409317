import React from 'react'

export const MainLoader = () => {

    return (
        <div className='load-container'>
            <div class="loader">
                <i class="loader-el"></i>
                <i class="loader-el"></i>
            </div>
        </div>
    )
}
