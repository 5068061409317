import * as Yup from "yup";


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


export const ApplicantFormSchema = Yup.object({
    given_name: Yup.string().min(2).required("Please enter your Name"),
    surname: Yup.string().required("Please enter your Surname"),
    email: Yup.string().email('Email is inValid').required("Please enter Email"),
    dob: Yup.string().required('Date is required'),
    gender: Yup.string().required("Please Select Gender")
});


export const ResidencetFormSchema = Yup.object({
    address1: Yup.string().min(2).required("Please enter your Address"),
    address2: Yup.string(),
    city: Yup.string().required("Please Select your city"),
    province: Yup.string().required("Please enter province"),
    postal_code: Yup.string().required('Postal code is required'),
    mobile_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
    fax: Yup.string().matches(phoneRegExp, 'Fax is not valid')
});


export const MailandShipFormSchema = Yup.object({
    m_address1: Yup.string().required("Please enter your Address"),
    m_address2: Yup.string(),
    m_city: Yup.string().required("Please Select your city"),
    m_province: Yup.string().required("Please enter province"),
    m_postal_code: Yup.string().required('Postal code is required'),
    shipcheck: Yup.string().required("Please Select Shipping Address"),
});

export const ResponsFormSchema = Yup.object({
    r_given_name: Yup.string().min(2, 'Name Must be at least 2 characters').required("Please enter your Name"),
    r_surname: Yup.string().required("Please enter your Surname"),
    r_dob: Yup.date().required('Date is required'),
    r_gender: Yup.string().required("Please Select Gender"),
    is_responsible: Yup.boolean().oneOf([true], 'Please accept the Terms and Conditions'),
});


export const termsandCondFormSchema = Yup.object({
    conditionsCheck: Yup.boolean().oneOf([true], 'Please accept the Terms and Conditions'),
});


export const PractitionerFormSchema = Yup.object({
    pra_given_name: Yup.string().required("Please Enter your Name"),
    pra_surname: Yup.string().required("Surname is Required"),
    pra_business_name: Yup.string().required("Please Enter your Clinic Name"),
    pra_address1: Yup.string().required("Please Enter your Address"),
    pra_address2: Yup.string(),
    pra_city: Yup.string().required("Please Enter your city"),
    pra_province: Yup.string().required("Please Enter province"),
    pra_postal_code: Yup.string().required('Postal Enter Postal Code'),
    pra_mobile_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone Number is required'),
    pra_fax: Yup.string().matches(phoneRegExp, 'Fax is not valid'),
    pra_email: Yup.string().email('Email is inValid').required('Email address is required'),
});
