import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FaLongArrowAltRight } from "react-icons/fa";
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';
import FormLabel from '@mui/material/FormLabel'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ApplicantFormSchema } from '../../../services/Schemas/MedicalFormValidation';
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux';
import { RegistrationApplicationAction } from '../../../services/Slices/RegistrationApplicationSlices';
import { MedicalRegistrationService } from '../../../services/Api/MedicalRegistrationApi';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import { toast } from 'react-toastify';

export const ApplicantForm = ({ handleNext, setApplicant }) => {

    const today = dayjs();

    const dispatch = useDispatch();

    const isInCurrentYear = (date) => date.get('year') >= dayjs().get('year') - 20;

    const { name, email, date_of_birth, gender } = useSelector((state) => state.register_form_data)

    const [cusGen, setCusGen] = React.useState(gender);
    const [emailCheckerLoading, setEmailCheckerLoading] = React.useState(false);
    const [emailValid, setEmailValid] = React.useState(true);
    const [emailPrev, setEmailPrev] = React.useState('');

    let initialValue = {
        'given_name': name.split(' ')[0],
        'surname': name.split(' ')[1],
        'email': email,
        'dob': date_of_birth,
        'gender': cusGen
    }

    const applicantFormik = useFormik({
        initialValues: initialValue,
        validationSchema: ApplicantFormSchema,
        onSubmit: (values, action) => {
            if (emailValid) {
                dispatch(RegistrationApplicationAction.setApplicantData(values))
                handleNext();
            } else {
                toast.warn('Please change the Email!', {
                    position: "bottom-right",
                    autoClose: 2000,
                    theme: "dark",
                });
            }

        }
    })
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = applicantFormik;

    const emailCheck = () => {
        applicantFormik.setFieldTouched('email');

        if (errors.email === undefined && values.email !== '' && values.email !== emailPrev) {
            setEmailCheckerLoading(true);
            const params = {
                email: values.email
            };

            axios.get('https://api.cannjah.com/medical/verify_email', { params })
                .then(response => {

                    setTimeout(function () { setEmailCheckerLoading(false) }, 1000)
                    if (response.status === 200) {
                        setEmailValid(true)
                        toast.success('Email Is Valied!', {
                            position: "bottom-right",
                            autoClose: 2000,
                            theme: "dark",
                        });
                    } else {
                        setEmailValid(false)
                        toast.error(response.data.details, {
                            position: "bottom-right",
                            autoClose: 2000,
                            theme: "dark",
                        });
                    }
                })
                .catch(error => {
                    setTimeout(function () { setEmailCheckerLoading(false) }, 1000)
                    setEmailValid(false)
                    toast.error(error.response.data.details, {
                        position: "bottom-right",
                        autoClose: 2000,
                        theme: "dark",
                    });
                });
            setEmailPrev(values.email);
        }
    }

    return (
        <form className=''>
            <h3 className='fw-bold mb-4'> Applicant Information </h3>

            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <label>Given Name</label>
                    <input type="text" name='given_name' onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="eg. Jacob"
                        value={values.given_name}
                    // onChange={(e) => setApplicant(e.target.value)}
                    />
                    {errors.given_name && touched.given_name ?
                        <p className="form-error">{errors.given_name}</p> : null}
                </Grid>
                <Grid item xs={12} md={6}>
                    <label>Surname</label>
                    <input type="text" name='surname' onChange={handleChange} onBlur={handleBlur} value={values.surname} className='cus-form-control' placeholder="eg. Jones" />
                    {errors.surname && touched.surname ?
                        <p className="form-error">{errors.surname}</p> : null}
                </Grid>
                <Grid item xs={12} md={9}>
                    <label>Email address</label>
                    <div className='input-tag'>
                        <input type="email" name='email' onChange={handleChange} onBlur={emailCheck} value={values.email} className='cus-form-control' placeholder="eg. infoeasy@gmail.com" />
                        {errors.email && touched.email ?
                            <p className="form-error">{errors.email}</p> : null}

                        {emailCheckerLoading ? (<div className='em-loader'><CircularProgress /></div>) : null}
                    </div>
                </Grid>
                <Grid item xs={12} md={9}>
                    <label>Date of Birth</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker onChange={(date) => applicantFormik.setFieldValue('dob', dayjs(date).format('YYYY-MM-DD'))
                        } onBlur={handleBlur} shouldDisableYear={isInCurrentYear} name='dob' value={dayjs(values.dob)} />
                    </LocalizationProvider>
                    {errors.dob && touched.dob ?
                        <p className="form-error">{errors.dob}</p> : null}
                </Grid>
                <Grid item md={8}>
                    <label>Gender</label>
                    <RadioGroup name="gender">
                        <Grid container spacing={3}>
                            <Grid item md={4}>
                                <FormControlLabel checked={cusGen === 'female' ? true : false} onChange={() => { setCusGen('female'); applicantFormik.setFieldValue('gender', 'female') }} value="female" control={<Radio />} label="Female" />
                            </Grid>
                            <Grid item md={4}>
                                <FormControlLabel checked={cusGen === 'male' ? true : false} onChange={() => { setCusGen('male'); applicantFormik.setFieldValue('gender', 'male') }} value="male" control={<Radio />} label="Male" />
                            </Grid>
                            <Grid item md={4}>
                                <FormControlLabel checked={cusGen === 'other' ? true : false} onChange={() => { setCusGen('other'); applicantFormik.setFieldValue('gender', 'other') }} value="other" control={<Radio />} label="Other" />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                    {errors.gender && touched.gender ?
                        <p className="form-error">{errors.gender}</p> : null}
                </Grid>
                <Grid item md={12}>
                    <Button className='fw-bold' onClick={handleSubmit}>
                        Continue <FaLongArrowAltRight />
                    </Button>
                </Grid>
            </Grid>
        </form >
    )
}
