import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FaLongArrowAltRight } from "react-icons/fa";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ApplicantFormSchema } from '../../../services/Schemas/MedicalFormValidation';
import { useFormik } from 'formik';
import { MailandShipFormSchema } from '../../../services/Schemas/MedicalFormValidation';
import { useDispatch, useSelector } from 'react-redux';
import { RegistrationApplicationAction } from '../../../services/Slices/RegistrationApplicationSlices';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';


export const MailandShipForm = ({ handleNext, handleBack }) => {

    const dispatch = useDispatch();

    const registrationData = useSelector((state) => state.register_form_data);

    const { mailing_address1, mailing_address2, mailing_city, mailing_province, mailing_postal_code, shipcheck } = registrationData;

    const [shipaddress, setShipaddress] = React.useState(shipcheck);


    let initialValue = {
        'm_address1': mailing_address1,
        'm_address2': mailing_address2,
        'm_province': mailing_city,
        'm_city': mailing_province,
        'm_postal_code': mailing_postal_code,
        'shipcheck': shipaddress
    }

    const mailandShipFormik = useFormik({
        initialValues: initialValue,
        validationSchema: MailandShipFormSchema,
        onSubmit: (values, action) => {

            dispatch(RegistrationApplicationAction.setMailandShipData(values))
            handleNext();
        }
    })

    const { values, touched, errors, handleBlur, handleChange, handleSubmit } = mailandShipFormik;

    const setAddressResidance = () => {

        mailandShipFormik.setValues({
            'm_address1': registrationData.residence_address1,
            'm_address2': registrationData.residence_address2,
            'm_province': registrationData.residence_province,
            'm_city': registrationData.residence_city,
            'm_postal_code': registrationData.residence_postal_code,
        })

    }

    return (
        <form className='' onSubmit={handleSubmit}>
            <h3 className='fw-bold mb-4'>Mailing Address (if different from above):</h3>
            <Grid container spacing={3} className='mb-3'>
                <Grid item xs={12}>
                    <Checkbox name="sameAbove" onChange={(event, newVlue) => {
                        if (newVlue) {
                            setAddressResidance();
                        }
                    }} />
                    <label>Same as above</label>
                </Grid>

                <Grid item xs={12}>
                    <label>Address Line 1</label>
                    <input type="text" value={values.m_address1} name='m_address1' onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                    {errors.m_address1 && touched.m_address1 ? <p className="form-error">{errors.m_address1}</p> : null}
                </Grid>
                <Grid item xs={12}>
                    <label>Address Line 2</label>
                    <input type="text" value={values.m_address2} onChange={handleChange} onBlur={handleBlur} name='m_address2' className='cus-form-control' placeholder="" />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <label>City</label>
                    <input type="text" name='m_city' value={values.m_city} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                    {errors.m_city && touched.m_city ? <p className="form-error">{errors.m_city}</p> : null}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <label>Province</label>
                    <input type="text" name='m_province' value={values.m_province} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                    {errors.m_province && touched.m_province ? <p className="form-error">{errors.m_province}</p> : null}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <label>Postal Code</label>
                    <input type="text" name="m_postal_code" value={values.m_postal_code} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                    {errors.m_postal_code && touched.m_postal_code ? <p className="form-error">{errors.m_postal_code}</p> : null}
                </Grid>


            </Grid>
            <h3 className='fw-bold mt-5 mb-3'>Shipping Address:</h3>
            <Grid container spacing={0} className='mb-4 pb-4'>
                <Grid item xs={12}>
                    <RadioGroup name="shipcheck">
                        <Grid container spacing={0}>
                            <Grid item md={12}>
                                <FormControlLabel checked={shipaddress === 'residence_address' ? true : false} onChange={() => { setShipaddress('residence_address'); mailandShipFormik.setFieldValue('shipcheck', 'residence_address') }} value="Same as residence address" control={<Radio />} label="Same as residence address" />
                            </Grid>
                            <Grid item md={12}>
                                <FormControlLabel checked={shipaddress === 'mailing_address' ? true : false} onChange={() => { setShipaddress('mailing_address'); mailandShipFormik.setFieldValue('shipcheck', 'mailing_address') }} value="Same as mailing address" control={<Radio />} label="Same as mailing address" />
                            </Grid>
                            <Grid item md={12}>
                                <FormControlLabel checked={shipaddress === 'practitioner_address' ? true : false} onChange={() => { setShipaddress('practitioner_address'); mailandShipFormik.setFieldValue('shipcheck', 'practitioner_address') }} value="Health care practitioner" control={<Radio />} label="Health care practitioner" />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                    {errors.shipcheck && touched.shipcheck ?
                        <p className="form-error">{errors.shipcheck}</p> : null}

                </Grid>
            </Grid>
            <Grid container spacing={0} className='mb-3'>
                <Grid item md={12}>
                    <Button className='fw-bold me-3' type='submit'>
                        Continue <FaLongArrowAltRight />
                    </Button>
                    <Button variant="dark" onClick={handleBack}>
                        Back
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}
