import React from 'react';
import { AiOutlineAlignLeft } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { AiOutlineHome } from "react-icons/ai";
import { FaRegUser, FaHeadphonesAlt } from "react-icons/fa";
import { useLocation } from "react-router-dom";

export const Sidebar = () => {

    const pathname = useLocation().pathname;

    const [showSidebar, setShowSidebar] = React.useState(true);

    const handleSidebar = (vale) => {
        setShowSidebar(vale);
    }

    return (
        <div className={showSidebar ? "sidebar show" : "sidebar"}>
            <div className="icon-btn" onClick={() => handleSidebar(!showSidebar)}>
                <AiOutlineAlignLeft />
            </div>
            <div className='profile-info'>
                <div className='profile-pic'>
                    <img src="https://picsum.photos/id/257/200/200" alt='Profiel pic' />
                </div>
                <div className='profioe-details'>
                    <h4>Sudhit Chuahan</h4>
                    <span>(Admin)</span>
                </div>

            </div>
            <div className='navbar-container'>
                <ul className='links-con'>
                    <li><Link to="/dashboard/" className={`${pathname === '/dashboard/' ? "active" : ""} nav-link`}><span className='me-2 h5'><AiOutlineHome /></span>Dashbaord</Link></li>
                    <li><Link to="/dashboard/profile" className={`${pathname === '/dashboard/profile' ? "active" : ""} nav-link`}><span className='me-2 h6'><FaRegUser /></span>Profile</Link></li>
                    <li><Link to="/dashboard/support" className={`${pathname === '/dashboard/support' ? "active" : ""} nav-link`}><span className='me-2 h6'><FaHeadphonesAlt /></span>Support</Link></li>
                </ul>
            </div>
        </div >
    )
}
