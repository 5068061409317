import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaLongArrowAltRight } from "react-icons/fa";
import gsap from 'gsap';

export const AcceptingPatients = () => {

    const headPatients = React.useRef(null);

    React.useEffect(() => {
        gsap.fromTo(".anim-patient", {
            y: 50, opacity: 0
        }, {
            y: 0, opacity: 1, duration: 0.3, stagger: { amount: 0.8 }, scrollTrigger: {
                trigger: headPatients.current,
                start: "bottom 85%"
            }
        });
    }, [])

    return (
        <div className="section-content Accepting-Patients pb-100 main-bg">
            <Container>
                <Row className="mx-0 ">
                    <Col sm={12} className="text-center anim-patient" ref={headPatients}>
                        <h2>Now Accepting <span className='text-white'>Patients!</span></h2>
                        <a className="btn btn-light rounded-0 px-4 py-2 mt-4" href="/contact/">Register <FaLongArrowAltRight /> </a>
                    </Col>
                </Row>
                <Row className="mx-0 mt-4 mt-sm-5">
                    <Col lg={4} className="">
                        <ul>
                            <li className='anim-patient'>&#62; Possession limits</li>
                            <li className='anim-patient'>Health Canada <span className='box-desktop'></span>Approved</li>
                        </ul>
                    </Col>
                    <Col lg={4} className="">
                        <ul>
                            <li className='anim-patient'>Medical Tax Benefits</li>
                            <li className='anim-patient'>Covered by Insurance - Direct Billing</li>

                        </ul>
                    </Col>
                    <Col lg={4} className="">
                        <ul>
                            <li className='anim-patient'>Wholesale Pricing</li>
                            <li className='anim-patient'>Compassionate Pricing<br />(Veterans, 1st Nations, Disable) </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
