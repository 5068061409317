import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Box from '@mui/material/Box';
import { FaLongArrowAltRight } from "react-icons/fa";
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { PractitionerFormSchema } from '../../../services/Schemas/MedicalFormValidation';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RegistrationApplicationAction } from '../../../services/Slices/RegistrationApplicationSlices';

export const PractitionerForm = ({ handleNext, handleBack }) => {

    const dispatch = useDispatch();
    const { practitioner_name, clinic_name, practitioner_address1, practitioner_address2, practitioner_city, practitioner_province, practitioner_postal_code, practitioner_mobile_number, practitioner_fax, practitioner_email } = useSelector((state) => state.register_form_data)

    let initialValue = {

        pra_given_name: practitioner_name.split(' ')[0],
        pra_surname: practitioner_name.split(' ')[1],
        pra_business_name: clinic_name,
        pra_address1: practitioner_address1,
        pra_address2: practitioner_address2,
        pra_city: practitioner_city,
        pra_province: practitioner_province,
        pra_postal_code: practitioner_postal_code,
        pra_mobile_number: practitioner_mobile_number,
        pra_fax: practitioner_fax,
        pra_email: practitioner_email,
    }

    const PractitionerForm = useFormik({
        initialValues: initialValue,
        validationSchema: PractitionerFormSchema,
        onSubmit: (values, action) => {

            dispatch(RegistrationApplicationAction.setPractitionerData(values));
            handleNext();
        }
    })

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = PractitionerForm;

    return (
        <form className='' onSubmit={handleSubmit}>
            <h3 className='fw-bold mb-2'>Health Care Practitioner Information</h3>
            <p className='mb-4'>(health care practitioner to complete only if consenting to receive cannabis on behalf of applicant)</p>
            <Grid container spacing={3} className='mb-3'>
                <Grid item xs={12} sm={6}>
                    <label>Name</label>
                    <input type="text" name='pra_given_name' value={values.pra_given_name} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="eg. Jack" />
                    {errors.pra_given_name && touched.pra_given_name ? <p className="form-error">{errors.pra_given_name}</p> : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <label>Surname</label>
                    <input type="text" name='pra_surname' value={values.pra_surname} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="eg. Jones" />
                    {errors.pra_surname && touched.pra_surname ? <p className="form-error">{errors.pra_surname}</p> : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <label>Clinic / Business Name:</label>
                    <input type="text" name='pra_business_name' value={values.pra_business_name} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                    {errors.pra_business_name && touched.pra_business_name ? <p className="form-error">{errors.pra_business_name}</p> : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <label>Email Address:</label>
                    <input type="email" name='pra_email' value={values.pra_email} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                    {errors.pra_email && touched.pra_email ? <p className="form-error">{errors.pra_email}</p> : null}
                </Grid>
                <Grid item xs={12}>
                    <label>Address Line 1</label>
                    <input type="text" name='pra_address1' value={values.pra_address1} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                    {errors.pra_address1 && touched.pra_address1 ? <p className="form-error">{errors.pra_address1}</p> : null}
                </Grid>
                <Grid item xs={12}>
                    <label>Address Line 2</label>
                    <input type="text" name='pra_address2' value={values.pra_address2} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <label>City</label>
                    <input type="text" name='pra_city' value={values.pra_city} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                    {errors.pra_city && touched.pra_city ? <p className="form-error">{errors.pra_city}</p> : null}

                </Grid>
                <Grid item xs={12} sm={4}>
                    <label>Province</label>
                    <input type="text" name='pra_province' value={values.pra_province} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                    {errors.pra_province && touched.pra_province ? <p className="form-error">{errors.pra_province}</p> : null}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <label>Postal Code</label>
                    <input type="text" name='pra_postal_code' value={values.pra_postal_code} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                    {errors.pra_postal_code && touched.pra_postal_code ? <p className="form-error">{errors.pra_postal_code}</p> : null}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <label>Phone Number</label>
                    <input type="text" name='pra_mobile_number' value={values.pra_mobile_number} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                    {errors.pra_mobile_number && touched.pra_mobile_number ? <p className="form-error">{errors.pra_mobile_number}</p> : null}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <label>Fax</label>
                    <input type="text" name='pra_fax' value={values.pra_fax} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                </Grid>
                <Grid item md={12}>
                    <Button className='fw-bold me-3' type='submit'>
                        Finish <FaLongArrowAltRight />
                    </Button>
                    <Button variant="dark" onClick={handleBack}>
                        Back
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}
