import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import "./common/Style.css";
import "./common/responsive.css";
import "./common/Custom.css";
import "./common/Loader.css";
import { store } from "./services/app/store";
import { Provider } from "react-redux";
import { BrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Router_pro from "./common/Router_pro";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ToastContainer position="bottom-right" />
      {/* <RouterProvider router={pro_routers} /> */}
      <Router_pro />
    </BrowserRouter>
  </Provider>
);
