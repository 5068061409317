import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import gsap from "gsap";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import { FaLongArrowAltRight } from "react-icons/fa";
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from 'react-bootstrap/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from "react-router-dom";

//Forms 
import { ApplicantForm } from './StepsForms/ApplicantForm';
import { PractitionerForm } from './StepsForms/PractitionerForm';
import { ResidenceForm } from './StepsForms/ResidenceForm';
import { ResponsibleForm } from './StepsForms/ResponsibleForm';
import { MailandShipForm } from './StepsForms/MailandShipForm';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { termsandCondFormSchema } from '../../services/Schemas/MedicalFormValidation';
import axios from "axios";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { RegistrationApplicationAction } from '../../services/Slices/RegistrationApplicationSlices';

export const MedicalForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const registrtionData = useSelector((state) => state.register_form_data);

    const { practionerOn } = registrtionData;

    const [activeStep, setActiveStep] = React.useState(0);

    const [applicantName, setApplicantName] = React.useState('');

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleAplicant = (valu) => {
        setApplicantName(valu);
    };

    const steps = [
        {
            id: 'Applicant',
            label: 'Section A: Applicant Information',
            component: <ApplicantForm handleBack={handleBack} handleNext={handleNext} setApplicant={handleAplicant} />
        },
        {
            id: 'Residence',
            label: 'Section B: Client Residence Information',
            component: <ResidenceForm handleBack={handleBack} handleNext={handleNext} />
        },
        {
            id: 'Mailing',
            label: 'Section B: Mailing & Shipping Information',
            component: <MailandShipForm handleBack={handleBack} handleNext={handleNext} />
        },
        {
            id: 'Responsible',
            label: 'Section C: Individual(s) Responsible For The Applicant',
            component: <ResponsibleForm handleBack={handleBack} handleNext={handleNext} applicant_name={applicantName} />
        }
    ];

    const termConditionForm = useFormik({
        initialValues: { conditionsCheck: false },
        validationSchema: termsandCondFormSchema,
        onSubmit: (values, action) => {
            FinalDataSave();
        }
    })
    const { values, errors, touched, handleBlur, handleSubmit, isValid } = termConditionForm;

    const FinalDataSave = () => {


        let { shipcheck, practionerOn, responsible_date_of_birth, responsible_gender, ...finalRegistionData } = registrtionData;

        console.log("Registion data : ", finalRegistionData)


        axios.post('https://api.cannjah.com/medical/medical_register', finalRegistionData, {

        }).then(response => {
            console.log(response.data);
            // setTimeout(function () { setEmailCheckerLoading(false) }, 1000)

            console.log('status check : ', response.status)

            if (response.status === 200) {
                // setEmailValid(true)
                Swal.fire({
                    title: 'Successfully Register',
                    icon: 'success',
                    html: response.data.details,
                    showCloseButton: false,
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonText: 'Ohk'
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(RegistrationApplicationAction.resetState())
                        navigate("/medical/");
                    }
                })
            } else {
                // setEmailValid(false)
                toast.error(response.data.details, {
                    position: "bottom-right",
                    autoClose: 2000,
                    theme: "dark",
                });
            }
        }).catch(error => {
            // setTimeout(function () { setEmailCheckerLoading(false) }, 1000)
            // setEmailValid(false)
            toast.error(error.response.data.details, {
                position: "bottom-right",
                autoClose: 2000,
                theme: "dark",
            });
        });

    }

    return (
        <div className="section-content medical-step-form">
            <Container className="">
                <Row className="mx-0 justify-content-center mt-50">
                    <Col lg={9}>
                        <div className="heading-container our-story-head text-md-start mb-50">
                            <h2>Registration Application</h2>
                            <p>Form A: Primary Residence</p>
                        </div>
                        <p className="mb-5 text-center text-md-start whowe-anim">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                        <Row className="mx-0 justify-content-center mt-50">
                            <Col lg={10}>
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    {steps.map((step, index) => (
                                        <Step key={step.id}>
                                            <StepLabel
                                            // optional={
                                            //     index === 3 ? (
                                            //         <Typography variant="caption">Last step</Typography>
                                            //     ) : null
                                            // }
                                            >
                                                {step.label}
                                            </StepLabel>
                                            <StepContent>
                                                <Typography>{step.description}</Typography>
                                                {step.component}
                                            </StepContent>
                                        </Step>
                                    ))}
                                    {
                                        practionerOn ? (<Step>
                                            <StepLabel>
                                                Section D: Health Care Practitioner Information
                                            </StepLabel>
                                            <StepContent>
                                                <PractitionerForm handleBack={handleBack} handleNext={handleNext} />
                                            </StepContent>
                                        </Step>) : null
                                    }

                                </Stepper>
                                {activeStep === (practionerOn ? steps.length + 1 : steps.length) && (
                                    <Paper square elevation={0} sx={{ p: 3 }}>
                                        <form onSubmit={handleSubmit}>
                                            <Typography className='mb-3'>All steps completed - you&apos;re finished</Typography>
                                            <h4>Section E: Attestation</h4>
                                            <p>The Applicant, or the Individual Responsible for the Applicant (if provided), must agree to the following:</p>
                                            <ul>
                                                <li>The applicant is ordinarily a resident in Canada;</li>
                                                <li>The information in the application and the medical document is correct and complete;</li>
                                                <li>The medical document is not being used to seek or obtain cannabis from another source;</li>
                                                <li>The original of the medical document accompanies the application; and</li>
                                                <li>The applicant will use cannabis only for their own medical purposes.</li>
                                            </ul>
                                            <div className='mb-5'>
                                                <FormControlLabel control={<Checkbox checked={values.conditionsCheck} onChange={(event, newval) => termConditionForm.setFieldValue('conditionsCheck', newval)} onBlur={handleBlur} name='conditionsCheck' />} label={`I accepted all above Terms and Condition!`} />
                                                {errors.conditionsCheck && touched.conditionsCheck ?
                                                    <p className="form-error">{errors.conditionsCheck}</p> : null}
                                            </div>

                                            <Button className='fw-bold' type='submit' disabled={isValid ? false : true}>
                                                Submit <FaLongArrowAltRight />
                                            </Button>
                                        </form>
                                    </Paper>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container >
        </div >
    )
}
