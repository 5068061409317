import axios from "axios";
import { toast } from 'react-toastify';

export class MedicalRegistrationService {

    static EmailChecker = (dataes, loader) => {
        return (dispatch) => {


            // let formData = JSON.stringify(data)

            // axios.post("http://127.0.0.1:8000/medical/medical_register", formData)
        }
    }
}




