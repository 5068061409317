import axios from "axios";
import { ContactAction } from "../Slices/ContactSlices";
import Swal from 'sweetalert2'


/* --------------------------------------------------------------------------------
                        Global State Fetch From Repidapi
-------------------------------------------------------------------------------- */

export const ContactData = (contactInfo) => {
    return async (dispatch) => {
        const sendContactRequest = async () => {

            dispatch(ContactAction.sendContactInfo(contactInfo));

            let data = JSON.stringify({
                "email": contactInfo.email,
                "related": contactInfo.related,
                "subject": contactInfo.subject,
                "message": contactInfo.message
            });

            axios.post('https://api.cannjah.com/contact', data, {
                headers: {
                    'Content-Type': 'text/plain'
                }
            }).then(res => {
                console.log("-----------");
            }).catch(err => {
                console.log("-----------");
            });

            Swal.fire({
                title: 'Form Sucessfully Submited!',
                text: "Thank You! We get in touch with you as quickly as we can.",
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#000000',
                confirmButtonText: 'ohk'
            })

        }
        try {
            await sendContactRequest();
        } catch (error) {
            console.error(error);
        }
    }
}
