import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Header } from '../components/Header';
import ReactDOM from "react-dom/client";
import { useDispatch } from 'react-redux';
import { ShopAction } from '../services/Slices/ShopSlices';

export const ThirdProduct = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://app.buddi.io/js/external-ropis.js";
        script.async = true;
        script.setAttribute("authId", "6959");
        document.getElementById("script").appendChild(script);
        dispatch(ShopAction.setShopShow(true))

        return () => {
            document.getElementById("script").removeChild(script);
        }
    }, []);

    return (
        <>
            <Header />
            <Container className="main-content overflow-hidden px-0 pt-5 mt-5">
                <div></div>
            </Container>
        </>
    )
}
