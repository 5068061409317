import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FaLongArrowAltRight } from "react-icons/fa";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { ResidencetFormSchema } from '../../../services/Schemas/MedicalFormValidation';
import { useDispatch, useSelector } from 'react-redux';
import { RegistrationApplicationAction } from '../../../services/Slices/RegistrationApplicationSlices';


export const ResidenceForm = ({ handleNext, handleBack }) => {

    const dispatch = useDispatch();

    const { residence_address1, residence_address2, residence_city, residence_province, residence_postal_code, mobile_number, fax } = useSelector((state) => state.register_form_data)

    let initialValue = {
        'address1': residence_address1,
        'address2': residence_address2,
        'city': residence_city,
        'province': residence_province,
        'postal_code': residence_postal_code,
        'mobile_number': mobile_number,
        'fax': fax
    }


    const residentform = useFormik({
        initialValues: initialValue,
        validationSchema: ResidencetFormSchema,
        onSubmit: (values, action) => {

            dispatch(RegistrationApplicationAction.setResidenceData(values))
            handleNext()
        }
    });



    const { values, touched, errors, handleBlur, handleChange, handleSubmit } = residentform;

    return (
        <form className='' onSubmit={handleSubmit}>
            <h3 className='fw-bold mb-4'>Client Residence Information</h3>
            <Grid container spacing={3} className='mb-3'>
                <Grid item xs={12}>
                    <label>Address Line 1</label>
                    <input type="text" value={values.address1} name='address1' onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                    {errors.address1 && touched.address1 ? <p className="form-error">{errors.address1}</p> : null}
                </Grid>
                <Grid item xs={12}>
                    <label>Address Line 2</label>
                    <input type="text" value={values.address2} onChange={handleChange} onBlur={handleBlur} name='address2' className='cus-form-control' placeholder="" />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <label>City</label>
                    <input type="text" name='city' value={values.city} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                    {errors.city && touched.city ? <p className="form-error">{errors.city}</p> : null}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <label>Province</label>
                    <input type="text" name='province' value={values.province} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                    {errors.province && touched.province ? <p className="form-error">{errors.province}</p> : null}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <label>Postal Code</label>
                    <input type="text" name="postal_code" value={values.postal_code} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                    {errors.postal_code && touched.postal_code ? <p className="form-error">{errors.postal_code}</p> : null}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <label>Mobile Number</label>
                    <input type="text" name='mobile_number' value={values.mobile_number} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                    {errors.mobile_number && touched.mobile_number ? <p className="form-error">{errors.mobile_number}</p> : null}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <label>Fax</label>
                    <input type="text" name="fax" value={values.fax} onChange={handleChange} onBlur={handleBlur} className='cus-form-control' placeholder="" />
                    {errors.fax && touched.fax ? <p className="form-error">{errors.fax}</p> : null}
                </Grid>
                <Grid item md={12}>
                    <Button className='fw-bold me-3 mt-4' type='submit'>
                        Continue <FaLongArrowAltRight />
                    </Button>
                    <Button variant="dark" className='mt-4' onClick={handleBack}>
                        Back
                    </Button>
                </Grid>
            </Grid>
        </form >
    )
}
