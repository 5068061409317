import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WhoWeAreBg from "./../../common/images/who-we-are.png";
import gsap from "gsap";


export const WhoWeAre = () => {

  const ourstoryHead = React.useRef(null);

  React.useEffect(() => {
    gsap.fromTo(ourstoryHead.current, { x: 1000 }, {
      x: 0, duration: 1, scrollTrigger: { trigger: ourstoryHead.current, start: "center 80%" }
    });

    gsap.fromTo(".our-story-img-conta", { x: -800 }, {
      x: 0, duration: 1, scrollTrigger: { trigger: ourstoryHead.current, start: "center 60%" }
    });

    gsap.fromTo(".story-anim", {
      y: 50, opacity: 0
    }, {
      y: 0, opacity: 1, duration: 0.3, stagger: { amount: 0.8 }, scrollTrigger: {
        trigger: ourstoryHead.current,
        start: "bottom 75%"
      }
    });
  })

  return (
    <Container fluid className="px-0">
      <div className="section-content main-bg">
        <Container>
          <Row className="mx-0">
            <Col lg={5} className="order-2 order-lg-1 text-center">
              <div className="our-story-img-conta">
                <img
                  src={WhoWeAreBg}
                  className="img-fluid our-story-img"
                  alt="Who We Are"
                  loading="lazy"
                  width="450"
                  height="460"
                />
              </div>
            </Col>
            <Col lg={7} className="order-1 text-center text-lg-start order-lg-2">
              <div className="heading-container who-we me-lg-0 mx-auto mb-50" ref={ourstoryHead}>
                <h2>
                  Our <span className="text-white">Story</span>
                </h2>
                <p className="main-bg px-5">It's Real...</p>
              </div>
              <h4 className="mb-4 story-anim">Experience is everything.</h4>
              <p className="mb-4 story-anim">
                We believe there's no better way to <b>innovate</b> if you haven’t gone through the <b>experience</b>, which makes us <b>unique</b>. From <b>muscular dystrophy</b> to <b>broken bones</b>, we’ve been through it all.
              </p>
              <p className="story-anim">
                Our motivation and drive to find the solution to our problems began from there. <b>We take our medication seriously</b>.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
};
